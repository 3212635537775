import React from 'react'
import { injectIntl } from 'react-intl'

import CountyLookupInputs from '~components/about/county-lookup-inputs'
import CountyLookupResults from '~components/about/county-lookup-results'

class CountyLookup extends React.Component {
  constructor (props) {
    super(props)
    this.state = { selectedCounty: null }
    this.handleSelectCounty = this.handleSelectCounty.bind(this)
  }

  handleSelectCounty (county) {
    if (county && county.value) {
      this.setState({ selectedCounty: county.value })
      // scroll to #local-results
      let element = document.getElementById('local-results')
      if (element) element.scrollIntoView()
    }
  }

  render () {
    const { messages } = this.props.intl
    let countyList = []
    let zipCodesList = []
    this.props.countyDirectoryData.forEach(county => {
      countyList.push({ value: county, label: county.county })
      // the CMS has some dupes so let's remove them using Set() - this doesn't remove dupes if they were added in different counties
      let uniqueZipCodesForCounty = [...new Set([...county.zipCodes])]

      // add the zip codes in a county to the full list of zip codes
      uniqueZipCodesForCounty.forEach(zipCode => {
        zipCodesList.push({ value: county, label: zipCode })
      })
    })

    // sort zip codes numerically
    zipCodesList.sort((a, b) => (a.label > b.label ? 1 : -1))

    return (
      <div id='local' className='bg-cyan py-16'>
        <div className='max-w-page flex all-center mx-auto'>
          <div className='flex-col w-full lg:w-1/2 lg:pr-8 pb-16'>
            <div className='headline-3'>{this.props.localLookup.headline}</div>
            <CountyLookupInputs
              counties={countyList}
              zipCodes={zipCodesList}
              onSelectCounty={this.handleSelectCounty}
            />
            <p className='body-1'>{this.props.localLookup.subline}</p>
          </div>
          <img src='/images/about-map.png' alt={messages['county-map']} className='hidden lg:block w-1/2 pl-8' />
        </div>
        <span id='local-results' />
        {this.state.selectedCounty && (
          <div className='max-w-page flex flex-col all-center my-8 py-8 bg-white shadow-lg'>
            <div className='headline-3'>{this.props.localLookup.resultsHeadline}</div>
            <div className='body-5'>{this.props.localLookup.resultsSubline}</div>
            <hr className='accent-rule my-8' aria-hidden />
            <CountyLookupResults county={this.state.selectedCounty} content={this.props.localLookup} />
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(CountyLookup)
