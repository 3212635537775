import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import AsyncSelect from 'react-select/async'

class CountyLookupInputs extends React.Component {
  constructor (props) {
    super(props)
    this.handleCountyChange = this.handleCountyChange.bind(this)
  }

  handleCountyChange (e) {
    this.props.onSelectCounty(e)
  }

  render () {
    const { messages } = this.props.intl
    let defaultOptions = [
      {
        label: messages['counties'],
        options: this.props.counties
      },
      {
        label: messages['zip-codes'],
        options: [] // don't show zips if they just click the dropdown
      }
    ]

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
      textAlign: 'center'
    }

    const formatGroupLabel = data => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    )

    const filterOptions = inputValue => {
      return [
        {
          label: messages['counties'],
          options: this.props.counties.filter(county => {
            return county.label.toLowerCase().includes(inputValue.toLowerCase())
          })
        },
        {
          label: messages['zip-codes'],
          // this is SLOW! @TODO figure out a way to get this usable on a trash phone.
          options: this.props.zipCodes.filter(zip => {
            return zip.label.includes(inputValue)
          })
        }
      ]
    }

    const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(filterOptions(inputValue))
      }, 1)
    }

    const noOptionsMessage = i => (
      <FormattedMessage id='no-county-results'>{txt => txt + ' "' + i.inputValue + '"'}</FormattedMessage>
    )

    const label = messages['county-search-label']

    const styles = {
      singleValue: (base, state) => {
        return { display: state.selectProps.menuIsOpen ? 'none' : 'block' }
      },
      dropdownIndicator: (base, state) => {
        return {
          'color': '#000',
          'display': 'flex',
          'padding': '8px',
          '-webkit-transition': 'color 150ms',
          'transition': 'color 150ms',
          'box-sizing': 'border-box'
        }
      },
      indicatorSeparator: (base, state) => {
        return {
          '-webkit-align-self': 'stretch',
          '-ms-flex-item-align': 'stretch',
          'align-self': 'stretch',
          'background-color': '#000',
          'margin-bottom': '8px',
          'margin-top': '8px',
          'width': '1px',
          'box-sizing': 'border-box'
        }
      },
      clearIndicator: (base, state) => {
        return {
          'color': '#000',
          'display': state.selectProps.menuIsOpen ? 'none' : 'flex',
          'padding': '8px',
          '-webkit-transition': 'color 150ms',
          'transition': 'color 150ms',
          'box-sizing': 'border-box'
        }
      },
      placeholder: (base, state) => {
        return {
          'color': '#000',
          'margin-left': '2px',
          'margin-right': '2px',
          'position': 'absolute',
          'top': '50%',
          '-webkit-transform': 'translateY(-50%)',
          '-ms-transform': 'translateY(-50%)',
          'transform': 'translateY(-50%)',
          'box-sizing': 'border-box'
        }
      }
    }

    return (
      <div className='search-dropdown-new w-full my-8'>
        <AsyncSelect
          // adds the x to clear the selected value
          isClearable
          // cache filter so it only runs once per input
          cacheOptions
          escapeClearsValue
          aria-label={label}
          placeholder={label}
          styles={styles}
          // this is supposed to speed the filter up
          ignoreAccents={false}
          loadOptions={loadOptions}
          classNamePrefix='county-lookup-select'
          className='whitespace-no-wrap'
          // if they don't type in anything what do they see by default?
          defaultOptions={defaultOptions}
          // the nicely stylted total number of filtered options
          formatGroupLabel={formatGroupLabel}
          noOptionsMessage={noOptionsMessage}
          onChange={this.handleCountyChange}
        />
      </div>
    )
  }
}

export default injectIntl(CountyLookupInputs)
