import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { contentfulReactComponentsOptions } from '~constants/styles'

const CountyLookupResults = props => {
  const { messages } = useIntl()
  let results = ''

  // if the county summary doesn't exist, use resultsSummaryDefaultText
  props.county.summary = props.county.summary || props.content.resultsSummaryDefaultText

  if (props.county && props.county.county) {
    results = (
      <div className='flex flex-col lg:flex-row'>
        <div className='lg:w-1/3 mb-8 lg:mb-0'>
          <img
            src='/images/about-first5county.png'
            alt={messages['county-map']}
            className='md:w-1/2 lg:w-full md:h-64 lg:h-full mx-auto object-cover'
          />
        </div>
        <div className='flex-grow mb-8 lg:mb-0 lg:ml-8'>
          <div className='headline-5 mb-4 text-blue'>{props.county.county}</div>
          <div className='extended mb-4 text-gray-dark'>
            <FormattedMessage id='summary' />
          </div>
          <div className='contentful'>
            {documentToReactComponents(props.county.summary.json, contentfulReactComponentsOptions)}
          </div>
        </div>
        <div className='lg:w-1/3 p-4 bg-gray-light'>
          <div className='extended mb-4 text-dark-gray'>
            <FormattedMessage id='contact-info' />
          </div>
          <div className='body-5 flex flex-col'>
            {props.county.address.address && (
              <span>
                {props.county.address.address.split('\n').map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              </span>
            )}
            {props.county?.phone && (
              <span>
                <FormattedMessage id='phone' />:{' '}
                <a href={'tel:' + props.county?.phone} className='text-blue'>
                  {props.county?.phone}
                </a>
              </span>
            )}
            {props.county?.website && (
              <span>
                <FormattedMessage id='website' />:{' '}
                <a href={props.county?.website} target='_blank' rel='noopener noreferrer' className='text-blue'>
                  {props.county?.website}
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return results
}

export default CountyLookupResults
